@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-family: Inter;
  background-color: #fff;
}

@font-face {
  font-family: "Polly_Rounded_Regular";
  src: url("../../../public/fonts/PollyRounded-Regular.ttf");
}

@font-face {
  font-family: "Polly_Rounded_Light";
  src: url("../../../public/fonts/PollyRounded-Light.ttf");
}
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap");

@layer base {
  * {
    @apply font-inter text-dark_gray;
  }
  html {
    @apply scroll-smooth overflow-x-hidden;
    @apply scrollbar-thin scrollbar-thumb-[#C1C1C1]/60 scrollbar-track-[#1C1D20];
    @apply bg-primary;
  }
  h1 {
    @apply text-[1.75rem] md:text-[2.375rem] xl:text-[3rem];
  }
  h2 {
    @apply text-[1.25rem] md:text-[1.625rem] xl:text-[2rem];
  }
  img {
    @apply pointer-events-none;
  }
}

@layer components {
  .t_section {
    @apply text-[32px] md:text-[42px] lg:text-[52px] xl:text-[62px] lg:leading-[87px];
  }
  .t_100px {
    @apply text-[65px] md:text-[70px] lg:text-[85px] xl:text-[100px] lg:leading-[87px];
  }
  .t_xs {
    @apply text-xs font-normal;
  }
  .t_sm {
    @apply text-xs md:text-sm font-normal;
  }
  .t_base {
    @apply text-xs md:text-sm xl:text-base font-normal;
  }
  .t_lg {
    @apply text-sm md:text-base xl:text-lg font-normal;
  }
  .t_xl {
    @apply text-base md:text-lg xl:text-xl font-normal;
  }
  .t_2xl {
    @apply text-lg md:text-xl xl:text-2xl font-normal;
  }
  .t_36px {
    @apply text-[1.25rem] md:text-[1.5rem] xl:text-[2rem] font-normal;
  }
  .t_40px {
    @apply text-[1.25rem] md:text-[1.875rem] xl:text-[2.5rem] font-normal;
  }
  .t_60px {
    @apply text-[40px] md:text-[50px] xl:text-[60px] font-normal;
  }
  .btn {
    @apply px-14 py-3 rounded-full text-white bg-dark_purple t_xl font-PollyRoundedRegular w-fit;
  }
}
